import "assets/css/loader.css"

type Props = {

    message?: string
};

const SpinnerComponent = ({message}: Props) => {
    return (
        <>
            <div className="page-loader">
                <div className="spinner">&nbsp;</div>
                <div className="txt"> {message && message.length ? message : (<>Virtual<br/>Chanakya</>)} </div>
            </div>
        </>
    );
};

export default SpinnerComponent;

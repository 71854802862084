import {createSlice} from '@reduxjs/toolkit'


export const initialState = {
    isSocket: false,
    onlineUsersData: {results: [], pagination: {}},
    onlineUsersStatus: {},
    notificationCount: 0

};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setNotificationCount: (state, action) => {
            console.log('setNotificationCount')
            state.notificationCount += action.payload;
        },
        setSocket: (state, action) => {
            // state.socket = action.payload;
            state.isSocket = action.payload;
        },
        setOnlineUsersStatus: (state, action) => {
            state.onlineUsersData = action.payload;

            let newUsers: any = {};
            state.onlineUsersData.results.forEach((user: any) => {
                newUsers[user.session_id] = user.online_status
            });
            state.onlineUsersStatus = newUsers
        },
    },
})

// Action creators are generated for each case reducer function
export const {setSocket, setOnlineUsersStatus, setNotificationCount} = chatSlice.actions
export const selectChat = (state: any) => state.chat;
export default chatSlice.reducer
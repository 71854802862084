import {configureStore} from "@reduxjs/toolkit";
import authReducer from "redux/features/auth/authSlice";
import counterReducer from "redux/features/counter/counterSlice";
import chatReducer from "redux/features/chat/chatSlice";

export default configureStore({
    reducer: {
        counter: counterReducer,
        auth: authReducer,
        chat: chatReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),

});


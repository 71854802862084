import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "redux/store";
// import { IntlProvider } from "react-intl";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/main.css";
import "./assets/css/custom.css";
import "./assets/css/owlcss.css";
// import coi-sw.js to this file


import Spinner from "components/spinners/Fallback-Spinner";
const LazyApp = lazy(() => import("App"));

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <HelmetProvider>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        {/* <IntlProvider locale={language} messages={messages}> */}
        <LazyApp />
        <ToastContainer newestOnTop />

        {/* </IntlProvider> */}
      </Suspense>
    </Provider>
  </HelmetProvider>
);

reportWebVitals(console.log);
